import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  constructor(private http: HttpClient) {}

  sendMessage(message: string) {
    return this.http.post(
      'https://api.telegram.org/bot' + '5744332731:AAHlxiaqo-PpQjEP67G4gVCwqPIhsLzctR4' + '/sendMessage',
      {
        chat_id: '-1002021636950',
        text: `[${environment.typeEnv}]: ${message}`,
      },
    );
  }

  notifyRegister(email: string) {
    if (environment.production) {
      this.sendMessage(`New user registered with email: ${email}`).subscribe();
    }
  }

  notifyAddedProperty(propertyId: string) {
    if (environment.production) {
      this.sendMessage(`New property added with id: ${propertyId}`).subscribe();
    }
  }
}
